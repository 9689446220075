@font-face {
  font-family: "f-r";
  src: url("fonts/Fleya-Regular.otf") format("opentype");
}

@font-face {
  font-family: "f-r-i";
  src: url("fonts/Fleya-Regular-Italic.otf") format("opentype");
}

body{
  overflow-x: hidden;
}

.vp-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-arrow {
  display: none;
}

*{
  outline: none;
  font-family: f-r;
}

header{
  width: 19%;
}

.slick-slide div img{
  margin: 0;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;
}

.arrows_slider{
  width: 20px;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78vh;
  z-index: 10;
}

.arrow_left{
  left: 5px;
}

.arrow_right{
  right: 5px;
}

.arrows_slider img{
  width: 20px;
}

.main_slider_container{
  float: right;
  position: relative;
  right: 2vw;
  top: 2vw;
  width: 60vw;
}

.buttons_nav{
  font-size: 3vw;
}

.buttons_nav:hover{
  opacity: 70%;
}

a:hover {
    opacity: 70%;
}

.loading_screen_projects{
  background-color: rgb(245, 247, 242);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 1;
  width: 100vw;
  position: fixed;
  z-index: 10000;
}

.loading_screen_projects img{
  width: 20vw;
}

.slider_container{
  position: fixed;
  right: 2vw;
  top: 5vh;
  height: 90vh;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.slider_container_main_page{
  position: fixed;
  right: 3vw;
  top: 5vh;
  height: 89.5vh;
  width: 70vw;
}

.slider_arrows{
  position: fixed;
  bottom: 5vh;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: end;
  right: 1vw;
}

.slider_container_img{
  height: 100%;
  cursor: pointer;
}


.slider_container_img img{
  height: 100%;
}

.slider_arrows div img{
  width: 4vw;
  padding: 0.4vw;
  cursor: pointer;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: inherit;
}

.loader_lightbox_page{
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rebeccapurple;
  left: 0;
  top: 0;
  z-index: 1;
}

.animated_header_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.animated_header_container img {
  width: 15vw;
}

.main_section_nav header,
.main_section_nav footer {
  position: fixed;
  padding: 2vw;
  padding-left: 3vw;
}

.main_section_nav header {
  top: -1vw;
}

.main_section_nav nav {
  bottom: 11vh;
}

.main_section_nav footer {
  bottom: 0;
}

.main_section_nav footer .footer_primary {
  display: flex;
}

.main_section_nav header h2,
.main_section_nav footer h2,
.main_section_nav nav h2 {
  cursor: pointer;
  margin: 0;
  font-weight: 100;
}

.video_slider{
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  height: 78vh;
  display: flex !important;
}

.video_slider_media_lightbox{
  width: 63vw;
  height: "500px";
  transform: scale(1);
}

.main_section_nav header h2{
  font-size: 3.4vw;
}

.main_section_nav footer h2{
  font-size: 2.5vw;
}

.main_section_nav header h1 {
  margin-top: 0;
}

.about_img_outer {
  margin-top: 6vw;
  display: flex;
  flex-direction: column;
}

.about_img_outer img {
  width: 50%;
}

.statement_about_outer p,
.about_about_outer p {
  line-height: 1.5;
  font-size: 17px;
}

.statement_about_outer {
  margin-top: 3vw;
}

.resume_blocks_outer {
  margin-top: 4vw;
  margin-bottom: 4vw;
}

.statement_about_outer h1,
.contact_main_title h1,
.press_main_title h1,
.resume_blocks_outer h1 {
  font-weight: 100;
  font-size: 25px;
}

.resume_cells_flex {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5vw;
}

.press_cell_block{
  display: block;
  width: 95%;
  margin-bottom: 1vw;
}

.footer_lightbox{
  display: flex;
  align-items: baseline;
  bottom: 1.5vw;
  margin-bottom: 15vw;
  margin-top: 2vw;
}

.img_slider_containter{
  height: 78vh;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}

.main_img_slider_containter{
  height: 89.5vh;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}

/* .img_slider_containter img{
  height: 100%;
} */


.footer_lightbox_left{
  width: 26%;
  margin-right: 4%;
}

.lightbox_title{
  text-transform: uppercase;
}


.footer_lightbox_left nav{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.footer_lightbox_left nav div{
  width: 50%;
}

.footer_lightbox_left nav div img{
  width: 48%;
  cursor: pointer;
  display: none;
}

.footer_lightbox_right{
  width: 70%;
}

.resume_cells_flex div:first-child {
  width: 15%;
}

.resume_cells_flex div:last-child {
  width: 80%;
}

.container {
  padding: 40px;
  background: #419be0;
}


.slick-slide{
  width: auto;
}

.slick-slide img {
  margin: auto;
  cursor: pointer;
  width: 100%;
}


.main_dynamic_logo{
  width: 100vw;
  height: 100vh;
  background-color: #f5f7f2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: fixed;
}

.main_dynamic_logo img{
  width: 20vw;
}

.about_container,
.contact_container,
.press_container{
  width: 63vw;
  right: 2vw;
  position: relative;
  float: right;
  top: 2vw;
}


.media_thumbnail_container{
  width: 66.1vw;
  right: 2vw;
  position: relative;
  float: right;
  top: 2vw;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


.lightbox_container{
  width: 63vw;
  right: 2vw;
  position: relative;
  float: right;
  top: 2vw;
}

.media_thumbnail_container{
  right: 0;
}


.media_thumbnails {
  width: 33.33%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.media_thumbnails_inner {
  width: 90%;
  cursor: pointer;
}

.media_thumbnails_inner p {
  margin: 0;
  margin-bottom: 1vw;
  font-size: 13px;
}

.media_thumbnails img {
  width: 100%;
  cursor: pointer;
}

.main_section_nav footer div {
  color: grey;
}

.main_section_nav footer .footer_primary div:first-child {
  padding-right: 1vw;
  border-right: 2px solid;
}

.main_section_nav footer .footer_primary div:last-child {
  margin-left: 1vw;
}


@media only screen and (max-width: 600px) {


  .main_section_nav header h2, 
  .main_section_nav footer h2, 
  .main_section_nav nav h2{
    font-size: 7vw;
  }

  .about_container, 
  .press_container{
    width: 94.5vw;
  }

  .media_thumbnail_container{
    width: 99vw;
  }

  .lightbox_container{
    width: 95.5vw;
  }

  .main_section_nav header{
    position: relative;
  }

  .media_thumbnail_container{
    top: 10vw;
    margin-bottom: 50vw;
  }

  .media_thumbnails{
    width: 49%;
  }

  .custom_arrow{
    width: 10px;
  }

  .video_slider{
    width: 63vw;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-self: center;
  }


  .custom_arrow img{
    width: 100%;
  }

  label{
    font-size: 10px;
  }

  .statement_about_outer p, 
  .about_about_outer p{
    font-size: 11px;
    margin-top: 0;
  }

  .footer_lightbox{
    flex-direction: column;
  }

  .footer_lightbox_left,
  .footer_lightbox_right{
    width: 100%;
  }

  .statement_about_outer h1, 
  .press_main_title h1, 
  .resume_blocks_outer h1,
  .lightbox_subtitles p,
  .lightbox_title p{
    font-size: 14px;
  }


  .resume_cells_flex *,
  .resume_cells_block * ,
  .footer_lightbox_right *,
  .resume_blocks_outer * {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .media_thumbnails_inner p{
    font-size: 10px;
    margin-bottom: 5px;
  }

  .img_slider_containter{
    height: 40vh;
  }

  .img_slider_containter img{
    height: 70%;
  }
  
  .main_dynamic_logo img{
    width: 40vw;
  }

  .loading_screen_projects img{
    width: 60vw;
  }
  

  .resume_cells_flex div:first-child{
    width: 100%;
  }

  .resume_cells_flex{
    flex-direction: column;
  }

  .main_section_nav footer{
    bottom: 0;
    z-index: 100;
    width: 100vw;
  }

  .footer_art, 
  .footer_about{
    background-color: rgb(245, 247, 242);
  }
  
  .footer_film{
    background-color: rgb(32, 32, 32);
  }

  .arrows_slider{
    display: none;
  }



}